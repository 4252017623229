import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/managed-releases",
  "date": "15th May 2024",
  "title": "Introducing Managed Releases!",
  "summary": "Exploring managed releases: an evolution of application manifests.",
  "author": "Karl Hepworth",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "presentation"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Using CI/CD systems, developers have been able to seamlessly package and deploy their applications for some time now.`}</p>
    <p>{`Developers have been requesting support for release management in order to track and manage application releases over
time. The goal is to provide fast-fails in CI/CD during deployments where a release has not been made.`}</p>
    <p>{`We are happy to announce support for managed releases to address these issues.`}</p>
    <h2>{`How does it work?`}</h2>
    <p>{`Managed releases are an evolution to manifest files introduced for CI/CD-based integrations last year. The manifest was
introduced to extend on functionality with third-party tools using data generated when packaging your application.`}</p>
    <p>{`It is similar to the current manifest workflow. When your application is packaged, Skpr will store information securely
at the infrastructure layer.`}</p>
    <p>{`When you go to deploy your application, Skpr will retrieve this information and use it as part of the deployment process
as a way of ensuring integrity across the lifetime of the pipelines.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/20240430/chart.png",
        "alt": "Workflow chart showing how releases fit into the deployment lifecycle"
      }}></img></p>
    <h2>{`What can this do for me?`}</h2>
    <p>{`The information protected by the infrastructure can be exposed to users via the command line interface. Additional
functionality provided to users via improvements to the CLI include:`}</p>
    <ul>
      <li parentName="ul">{`Releases can be listed, so you can identify when a release was packaged.`}</li>
      <li parentName="ul">{`Release manifests can be inspected.`}</li>
      <li parentName="ul">{`Deployments for releases that don't exist will fail the pipeline before deployment starts.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/posts/20240430/release-list-info.gif",
        "alt": "Animated image showing Skpr CLI managing releases"
      }}></img></p>
    <h2>{`How do I find out more?`}</h2>
    <p>{`For more information on the improvements to the user experience,
`}<a parentName="p" {...{
        "href": "https://docs.skpr.io/using-skpr/releases/list/"
      }}>{`see our documentation`}</a>{` for more information.`}</p>
    <h2>{`In closing`}</h2>
    <p>{`Support for platform-managed releases continues our efforts to improve your security posture. By letting Skpr manage an
application manifest, the integrity and transparency of your release pipeline is enhanced.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      